<template>
  <div class="Container">
    <vcl-facebook v-if="isFetching" />
    <div v-if="!isFetching">
      <div v-for="item in items" :key="item.id" class="Element">
        <a
          :href="'https://twitter.com/i/web/status/' + item.idLink"
          target="_blank"
          rel="noopener"
        >
          <v-lazy-image
            src-placeholder="/assets/images/Wordmark.svg"
            :src="item.user.profileImage"
            :alt="item.user.name"
          />
          <div class="Tweet">
            <header>
              <div v-if="item.retweetedBy">
                Retweeted by {{ item.retweetedBy }}
              </div>
              <div class="Name">
                {{ item.user.name }}
              </div>
              <div class="Username">@{{ item.user.screenName }}</div>
            </header>
            <main class="TwittContent">
              {{ item.text }}
            </main>
            <footer>{{ formatTwitterDate(item.createdAt) }}</footer>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { VclFacebook } from 'vue-content-loading';
import { formatTwitterDate } from '~/lib/date';

export default {
  components: {
    VclFacebook,
  },
  props: {
    isFetching: {
      type: Boolean,
      value: true,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    formatTwitterDate,
  },
};
</script>

<style lang="postcss" scoped>
@import '~/assets/vars.pcss';
@import '~/assets/mixins.pcss';

.Container {
  max-height: 400px;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 10px;
  background-color: $soft-background;
}

.Element {
  padding: 10px;
  border-radius: 10px;
  background-color: white;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &:hover {
    background-color: $soft-background;
  }

  & img {
    max-width: 48px;
    max-height: 100%;
    border-radius: 50%;
  }

  & > a {
    color: $soft-black;
    font-weight: normal;
    display: flex;
    align-items: flex-start;
    text-decoration: none;

    &::after {
      content: none;
    }
  }

  & .Tweet {
    width: 100%;
    margin-left: 15px;
  }
}

header {
  & .Name {
    color: $black;
    font-weight: bold;
  }

  & .Username {
    color: $grey;

    @mixin smallFont;
  }
}

footer {
  text-align: right;

  @mixin mediumFont;
}
</style>
