<template>
  <div>
    <main class="Main">
      <section class="MainArticles">
        <article-block
          v-for="item in items"
          :key="item.id"
          :is-fetching="mainLoading"
          :article="item"
          class="ArticleBlock"
        />
      </section>
      <client-only>
        <section class="Social">
          <div class="GoogleNews">
            <h2>Turismo Sicilia su Google News</h2>
            <GoogleWidget />
          </div>
          <div class="YouTube">
            <h2>Turismo Sicilia su YouTube</h2>
            <YouTubeWidget
              :items="youtubeItems"
              :is-fetching="youtubeLoading"
            />
          </div>
          <div class="Twitter">
            <h2>Turismo Sicilia su Twitter</h2>
            <twitter-timeline
              :is-fetching="twitterLoading"
              :items="twitterItems"
            />
          </div>
          <div class="Instagram">
            <h2>Turismo Sicilia su Instagram</h2>
            <vue-picture-swipe
              class="InstagramGallery"
              :is-fetching="instagramLoading"
              :items="instagramItems"
            />
          </div>
          <div class="Flickr">
            <h2>Turismo Sicilia su Flickr</h2>
            <vue-picture-swipe
              class="FlickrGallery"
              :is-fetching="flickrLoading"
              :items="flickrItems"
            />
          </div>
        </section>
      </client-only>
    </main>
  </div>
</template>

<script>
import { fetchMainNews } from '~/plugins/sanity';
import {
  fetchTwitter,
  fetchInstagram,
  fetchFlickr,
  fetchYoutube,
} from '~/plugins/api-ondemand';
import ArticleBlock from '~/components/ArticleBlock.vue';
import TwitterTimeline from '~/components/TwitterTimeline.vue';
import VuePictureSwipe from '~/components/VuePictureSwipe.vue';
import YouTubeWidget from '~/components/YouTubeWidget.vue';
import GoogleWidget from '~/components/GoogleWidget.vue';

export default {
  components: {
    ArticleBlock,
    TwitterTimeline,
    VuePictureSwipe,
    YouTubeWidget,
    GoogleWidget,
  },
  asyncData({ error, store }) {
    store.commit('updateMainArticleLoading', true);
    return fetchMainNews()
      .then((res) => ({ items: res }))
      .catch(error)
      .finally(() => store.commit('updateMainArticleLoading', false));
  },
  data() {
    return {
      youtubeItems: [],
      twitterItems: [],
      instagramItems: [],
      flickrItems: [],
    };
  },
  computed: {
    mainLoading() {
      return this.$store.state.mainArticleLoading;
    },
    youtubeLoading() {
      return this.$store.state.youtubeLoading;
    },
    twitterLoading() {
      return this.$store.state.twitterLoading;
    },
    instagramLoading() {
      return this.$store.state.instagramLoading;
    },
    flickrLoading() {
      return this.$store.state.flickrLoading;
    },
  },
  async created() {
    this.$store.commit('updateTwitterLoading', true);
    this.$store.commit('updateYoutubeLoading', true);
    this.$store.commit('updateInstagramLoading', true);
    this.$store.commit('updateFlickrLoading', true);

    fetchYoutube(this.$axios).then(({ items }) => {
      this.youtubeItems = items;
      this.$store.commit('updateYoutubeLoading', false);
    });

    fetchTwitter(this.$axios).then(({ items }) => {
      this.twitterItems = items;
      this.$store.commit('updateTwitterLoading', false);
    });

    fetchInstagram(this.$axios).then(({ items }) => {
      this.instagramItems = items;
      this.$store.commit('updateInstagramLoading', false);
    });

    fetchFlickr(this.$axios).then(({ items }) => {
      this.flickrItems = items;
      this.$store.commit('updateFlickrLoading', false);
    });
  },
  head() {
    return {
      title: 'Bookinsicily - Turismo, enogastronomia e cultura in Sicilia',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          hid: 'description',
          name: 'description',
          content:
            'Bookinsicily è una piattaforma che raccoglie tutte le news presenti sul web che riguardano il turismo in Sicilia.',
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content:
            'Comiso, News, Notizie Comiso, Ragusa, Provincia di Ragusa, Aeroporto di Comiso, Politica Comiso, Cronaca Comiso, Cultura Comiso, Sport Comiso, Ultime notizie Comiso, Google News Comiso, YouTube Comiso, Flickr Comiso, Instagram Comiso, Twitter Comiso, Facebook Comiso, trip, holiday, vacation, sicily, sea, mountain, tourism, b&amp;b, portal, packages, turist, turismo, sicilia, vacanze, eventi, cultura, enogastronomia',
        },
        { name: 'msapplication-TileColor', content: '#86CFED' },
        {
          hid: 'theme-color',
          name: 'theme-color',
          content: '#86CFED',
        },
        {
          hid: 'mobile-web-app-capable',
          name: 'mobile-web-app-capable',
          content: 'yes',
        },
        {
          hid: 'application-name',
          name: 'application-name',
          content:
            'Bookinsicily - Turismo, enogastronomia e cultura in Sicilia',
        },
        {
          hid: 'apple-mobile-web-app-capable',
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
        {
          hid: 'apple-mobile-web-app-status-bar-style',
          name: 'apple-mobile-web-app-status-bar-style',
          content: '#86CFED',
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content:
            'Bookinsicily - Turismo, enogastronomia e cultura in Sicilia',
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary',
        },
        {
          hid: 'twitter:site',
          name: 'twitter:site',
          content: '@bookinsicily',
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: 'Bookinsicily - Tutte le notizie su Comiso in un solo sito',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${process.env.BASE_URL}/sharer-image.png`,
        },
      ],
    };
  },
};
</script>

<style lang="postcss" scoped>
@import '~/assets/vars.pcss';
@import '~/assets/mixins.pcss';

img {
  max-width: 100%;
  height: 80px;
}

.Main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  user-select: none;
  cursor: default;

  & > a {
    width: 100%;
  }
}

.ArticleBlock {
  margin: 20px;
}

.MainArticles {
  width: 100%;
}

.Social {
  width: 100%;
  padding: $main-padding;

  & > div:not(:last-child) {
    margin-bottom: 30px;
  }
}

.Google {
  padding: $main-padding;
}

.InstagramGallery,
.FlickrGallery {
  background-color: $soft-background;
  padding: 10px;
  border-radius: 10px;
}
</style>
