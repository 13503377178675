<template>
  <div class="YouTubeWidget">
    <div v-if="isFetching" class="LoadingWrapper VideoList">
      <vcl-twitch />
      <vcl-twitch />
      <vcl-twitch />
      <vcl-twitch />
      <vcl-twitch />
      <vcl-twitch />
      <vcl-twitch />
      <vcl-twitch />
    </div>
    <ul v-if="!isFetching" class="VideoList">
      <li
        v-for="(item, index) in items"
        :key="index"
        :data-item="index"
        :class="`VideoItem L${index}`"
      >
        <h5 class="VideoTitle">
          {{ item.title }}
        </h5>
        <lazy-youtube-video
          :src="'https://www.youtube.com/embed/' + item.id"
          aspect-ratio="1:1"
          preview-image-size="hqdefault"
          :thumbnail-listeners="{
            click: onPlayerChange(item.id),
          }"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { VclTwitch } from 'vue-content-loading';

export default {
  components: {
    VclTwitch,
  },
  props: {
    isFetching: {
      type: Boolean,
      value: true,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      hideTitle: [],
    };
  },
  methods: {
    onPlayerChange(videoId) {
      return () => {
        if (this.isPlaying) {
          const iframe = document.querySelector(
            `#${window.CSS.escape(this.isPlaying)} iframe`
          );
          iframe.src = iframe.src.replace('autoplay=1', 'autoplay=0');
        }

        this.isPlaying = videoId;
      };
    },
  },
};
</script>

<style lang="postcss" scoped>
@import '~/assets/media.pcss';
@import '~/assets/vars.pcss';

.VideoList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  grid-auto-flow: dense;

  @media (--medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--big) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.VideoItem {
  position: relative;
}

.VideoTitle {
  position: absolute;
  left: $main-padding;
  top: $main-padding;
  z-index: 1;
  color: $white;
  text-transform: uppercase;
}

.LoadingWrapper svg {
  max-height: 200px;
  margin-bottom: $main-padding;
}
</style>

<style lang="postcss">
@import 'vue-lazy-youtube-video/dist/style.css';
@import '~/assets/vars.pcss';

.y-video__media {
  border: none;
  object-fit: cover;
}

.y-video,
.y-video__media,
iframe {
  border-radius: 10px;
}

.y-video .y-video__inner iframe {
  z-index: 1;
}
</style>
